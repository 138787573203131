<div class="restrict-width">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
   class="filter-container">
   <div class="filter" fxFlex>
    <mat-form-field appearance="fill">
      <mat-label>Department Code</mat-label>
      <input
        matInput
        [formControl]="deptControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onDepartmentChange($event.option.value)">
        <mat-option *ngFor="let option of filteredDeptOptions | async" [value]="option.value">
          {{ option.view }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
    <div class="filter">
      <mat-form-field appearance="fill">
        <mat-label>Sort by</mat-label>
        <mat-select [(value)]="selectedSort" (selectionChange)="initLoad()">
          <mat-option *ngFor="let option of orderByOptions" [value]="option">
            {{option.displayText}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <app-review-detail [reviewData]="reviewData" [showEmptyMsg]="false" 
  [links]="true">
  </app-review-detail>
  <div *ngIf="reviewData.length && !noMore" style="text-align: center;">
    <button mat-raised-button color="primary" class="btn-block" (click)="getMore()">
      More
    </button>
  </div>
</div>
