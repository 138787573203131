<div class="course-list-container">
  <div class="scrollable-filters">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start center"
      class="filter-container"
    >
      <!-- Department Filter -->
      <div class="filter">
        <mat-form-field appearance="fill">
          <mat-label>Select a Department</mat-label>
          <input
            matInput
            [formControl]="deptControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onDeptFilterChange($event.option.value)">
            <mat-option *ngFor="let option of filteredDeptOptions | async" [value]="option.value">
              {{ option.view }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <!-- Semester Filter -->
      <div class="filter">
        <mat-form-field appearance="fill">
          <mat-label>Semester Filter</mat-label>
          <input
            matInput
            [formControl]="semesterControl"
            (ngModelChange)="onSemesterFilterChange($event)"
            [matAutocomplete]="autoSemester"
          />
          <mat-autocomplete #autoSemester="matAutocomplete">
            <mat-option *ngFor="let option of filteredSemesterOptions | async" [value]="option.value">
              {{ option.view }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <!-- Difficulty Filter -->
      <div class="filter">
        <mat-form-field appearance="fill">
          <mat-label>Difficulty Less than...</mat-label>
          <input matInput type="number" [(ngModel)]="difficultyValue" (ngModelChange)="onDifficultyFilterChange($event)">
        </mat-form-field>
      </div>

      <!-- Workload Filter -->
      <div class="filter">
        <mat-form-field appearance="fill">
          <mat-label>Workload Less than...</mat-label>
          <input matInput type="number" [(ngModel)]="workloadValue" (ngModelChange)="onWorkloadFilterChange($event)">
        </mat-form-field>
      </div>

      <!-- Rating Filter -->
      <div class="filter">
        <mat-form-field appearance="fill">
          <mat-label>Rating higher than...</mat-label>
          <input matInput type="number" [(ngModel)]="ratingValue" (ngModelChange)="onRatingFilterChange($event)">
        </mat-form-field>
      </div>
    </div>
  </div>
</div>

  <div class="table-container mat-elevation-z1">
    <table mat-table matSort [dataSource]="dataSource" [trackBy]="trackById" matSortActive="CourseNumber"
      matSortDirection="asc">
      <!-- CourseNumber Column -->
      <ng-container matColumnDef="CourseNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Course Number</th>
        <td mat-cell *matCellDef="let class">{{class.CourseNumber}}</td>
      </ng-container>

      <!-- ClassName Column -->
      <ng-container matColumnDef="ClassName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Course Name</th>
        <td mat-cell *matCellDef="let class">{{class.ClassName}}</td>
      </ng-container>

      <!-- RatingCount Column -->
      <ng-container matColumnDef="RatingCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reviews</th>
        <td mat-cell *matCellDef="let class">{{class.RatingCount}}</td>
      </ng-container>

      <!-- DifficultyAvg Column -->
      <ng-container matColumnDef="DifficultyAvg">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Difficulty (1&#x2011;5)</th>
        <td mat-cell *matCellDef="let class">{{class.RatingCount ? (class.DifficultyAvg | number: '1.0-1') : '--'}}</td>
      </ng-container>

      <!-- WorkloadAvg Column -->
      <ng-container matColumnDef="WorkloadAvg">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Hours spent per week">Workload (hrs/wk)</th>
        <td mat-cell *matCellDef="let class">{{class.RatingCount ? (class.WorkloadAvg | number: '1.0-1') : '--'}}</td>
      </ng-container>

      <!-- RatingAvg Column -->
      <ng-container matColumnDef="RatingAvg">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Rating (1&#x2011;5)</th>
        <td mat-cell *matCellDef="let class">{{class.RatingCount ? (class.RatingAvg | number: '1.0-1') : '--'}}</td>
      </ng-container>

      <!-- Semester Column -->
      <ng-container matColumnDef="Semester">
        <th mat-header-cell *matHeaderCellDef class="center">Semesters</th>
        <td mat-cell *matCellDef="let class" class="center">
          <mat-icon *ngIf="class.season.fall" svgIcon="icons:fall"></mat-icon>
          <mat-icon *ngIf="class.season.spring" svgIcon="icons:spring"></mat-icon>
          <mat-icon *ngIf="class.season.summer" svgIcon="icons:summer"></mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="rowClick($event, row)"></tr>
    </table>
  </div>

