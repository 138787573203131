<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{headerText}}</mat-card-title>
    </mat-card-header>

    <form class="login-form" [formGroup]="reviewForm" (ngSubmit)="onSubmit()">
      <mat-card-content>

      <!-- Department Field -->
      <mat-form-field id="Department" class="form-field-full-width" floatLabel="always" appearance="outline">
        <mat-label>Department</mat-label>
        <input type="text" matInput [matAutocomplete]="auto" formControlName="Department" required>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onDepartmentSelected($event.option.value)">
          <mat-option *ngFor="let department of filteredDepartments | async" [value]="department">
            {{department}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="reviewForm.controls.Department.hasError('required')">
          Please select or enter a department
        </mat-error>
      </mat-form-field>

      <!-- CourseNumber Field -->
      <mat-form-field id="CourseNumber" class="form-field-full-width" floatLabel="always" appearance="outline">
        <mat-label>Course</mat-label>
        <mat-select formControlName="CourseNumber" required>
          <mat-option *ngFor="let course of courses" [value]="course.CourseNumber">
            {{course.CourseNumber}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="reviewForm.controls.CourseNumber.hasError('required')">
          Please select a course
        </mat-error>
      </mat-form-field>

      <!-- ClassName Field -->
      <mat-form-field id="ClassName" class="form-field-full-width" floatLabel="always" appearance="outline">
        <mat-label>Course Name</mat-label>
        <mat-select formControlName="ClassName" required>
          <mat-option *ngFor="let className of filteredClassNames" [value]="className.ClassName">
            {{ className.ClassName }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="reviewForm.controls.ClassName.hasError('required')">
          Please select a class name
        </mat-error>
      </mat-form-field>

        <!-- Semester Field -->
        <mat-form-field id="semester" class="form-field-full-width" floatLabel="always" appearance="outline">
          <mat-label>Semester</mat-label>
          <mat-select [compareWith]="compareSemyear" formControlName="semyear" required>
            <mat-option *ngFor="let semyear of semesterYears" [value]="semyear">
              {{semyear.semester + " " + semyear.year}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="reviewForm.controls.semyear.hasError('required')">
            Please select a semester
          </mat-error>
        </mat-form-field>

        <!-- Professor Field -->
      <mat-form-field id="Professor" class="form-field-full-width" floatLabel="always" appearance="outline">
        <mat-label>Professor</mat-label>
        <mat-select formControlName="Professor">
          <mat-option *ngFor="let prof of filteredProfessors" [value]="prof">
            {{ prof }}
          </mat-option>
        </mat-select>
      </mat-form-field>

        <!-- Difficulty Field -->
        <mat-form-field id="difficulty" class="form-field-full-width" floatLabel="always" appearance="outline">
          <mat-label>Difficulty</mat-label>
          <mat-select formControlName="difficulty" required>
            <mat-option *ngFor="let difficulty of difficulties" [value]="difficulty.value">
              {{difficulty.view}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="reviewForm.controls.difficulty.hasError('required')">
            Please select a difficulty
          </mat-error>
        </mat-form-field>

        <!-- Workload Field -->
        <mat-form-field id="workload" class="form-field-full-width" floatLabel="always" appearance="outline">
          <mat-label>Workload (hours/week)</mat-label>
          <input matInput type="number" required min="{{ minWorkload }}" max="{{ maxWorkload }}" autocomplete="off"
            name="work" formControlName="workload">
          <mat-error *ngIf="reviewForm.controls.workload.hasError('min')">
            Workload minimum is {{minWorkload}}
          </mat-error>
          <mat-error *ngIf="reviewForm.controls.workload.hasError('max')">
            Workload maximum is {{maxWorkload}}
          </mat-error>
          <mat-error *ngIf="reviewForm.controls.workload.hasError('required')">
            Please enter a workload
          </mat-error>
        </mat-form-field>

        <!-- Rating Field -->
        <mat-form-field id="rating" class="form-field-full-width" floatLabel="always" appearance="outline">
          <mat-label>Rating</mat-label>
          <mat-select formControlName="rating" required>
            <mat-option *ngFor="let rating of ratings" [value]="rating.value">
              {{rating.view}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="reviewForm.controls.rating.hasError('required')">
            Please select a rating
          </mat-error>
        </mat-form-field>

        <!-- Professor Rating Field -->
        <mat-form-field id="profRating" class="form-field-full-width" floatLabel="always" appearance="outline">
          <mat-label>Professor Rating</mat-label>
          <mat-select formControlName="profRating">
            <mat-option *ngFor="let profRating of profRatings" [value]="profRating.value">
              {{profRating.view}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Title Field -->
        <mat-form-field id="title" class="form-field-full-width" floatLabel="always" appearance="outline">
          <mat-label>Title</mat-label>
          <input matInput placeholder="Review Title" autocomplete="off" formControlName="title" required name="title"
            placeholder="My favorite course so far">
          <mat-error *ngIf="reviewForm.controls.title.hasError('required')">
            Please enter a title
          </mat-error>
        </mat-form-field>

        <!-- Review Body Field -->
        <mat-form-field id="review" class="form-field-full-width" floatLabel="always" appearance="outline">
          <mat-label>Course Review Body</mat-label>
          <textarea matInput type="textarea" rows="12" formControlName="review" required autocomplete="off"
            placeholder="{{reviewPlaceholder}}"></textarea>
          <mat-error *ngIf="reviewForm.controls.review.hasError('required'); else reviewExists">
            Please enter a review
          </mat-error>
          <ng-template #reviewExists>
            <mat-error *ngIf="reviewForm.controls.review.hasError('minlength')">
              Your review is {{ countReviewChars() }} long (it should be at least {{ minReviewCharLength}}).
            </mat-error>
          </ng-template>
        </mat-form-field>

        <!-- Professor Review Body Field -->
        <mat-form-field id="profReview" class="form-field-full-width" floatLabel="always" appearance="outline">
          <mat-label>Professor Review Body</mat-label>
          <textarea matInput type="textarea" rows="12" formControlName="profReview" autocomplete="off"
            placeholder="{{profReviewPlaceholder}}"></textarea>
          <mat-error *ngIf="reviewForm.controls.profReview.hasError('required'); else reviewExists">
            Please enter a review
          </mat-error>
          <ng-template #reviewExists>
            <mat-error *ngIf="reviewForm.controls.profReview.hasError('minlength')">
              Your review is {{ countReviewChars() }} long (it should be at least {{ minReviewCharLength}}).
            </mat-error>
          </ng-template>
        </mat-form-field>

      </mat-card-content>
      <button [disabled]="loading" mat-raised-button color="primary" class="btn-block">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Submit
      </button>
      <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
    </form>
  </mat-card>
</div>