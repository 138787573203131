<body>
  <body>
    <header>
      <a href="https://illini.courses" target="_blank" rel="noopener noreferrer">
        <img src="../../../logo.jpg" alt="Logo">
      </a>
      <h1>
        <a href="https://illini.courses" target="_blank" rel="noopener noreferrer">
          Welcome to illini.courses!
        </a>
      </h1>
      <h2>The unofficial site for all things courses at the University of Illinois Urbana-Champaign</h2>
    </header>
  <main>
    <section>
      <h2>About</h2>
      <p>illini.courses is the result of wanting to bring a few great (but mainly department specific) tools to all of UIUC.</p>
      <p>Primarily, we wanted to give students a resource to help them find actually useful information about courses (content taught, professor, workload, difficulty, quality of course, etc.) and to help them make informed decisions about their course selections. We also wanted to give students a place to share their experiences with courses and professors, so that future students can benefit from their insights.</p>
      <p>The specific resources used as inspiration include <a href="https://uiucmcs.org" target="_blank" rel="noopener noreferrer">uiucmcs.org</a> (the base app for illini.courses), <a href="https://mscshub.com" target="_blank" rel="noopener noreferrer">mscshub.com</a> (the base app for <a href="https://uiucmcs.org" target="_blank" rel="noopener noreferrer">uiucmcs.org</a>), and <a href="https://wiki.hkn.illinois.edu/" target="_blank" rel="noopener noreferrer">wiki.hkn.illinois.edu</a> (the inspiration for logging student experiences in courses to benefit other students), and <a href="https://waf.cs.illinois.edu/discovery/grade_disparity_between_sections_at_uiuc/" target="_blank" rel="noopener noreferrer">https://waf.cs.illinois.edu/discovery/grade_disparity_between_sections_at_uiuc/</a> (GPA data and visualization).</p>
    </section>

    <section>
      <h2>How to Use</h2>
        <h3>Courses Page</h3>
          <p>To get started, click <a href="https://illini.courses" target="_blank" rel="noopener noreferrer">Courses</a> and type in your Department of interest to see courses and their stats, including Difficulty, Workload, and Rating. You can also browse through different departments or filter courses by semester.</p>
          <p>You can also use the filters to find courses that fit your criteria.</p>
          <p>Also, you can clicks on the column names (like Course Number, Difficulty, etc) to sort the results by those columns.</p>
          <p>Once you find a course you're interested in, click on it to see the reviews and GPA data for that course, similar to <a href="https://waf.cs.illinois.edu/discovery/grade_disparity_between_sections_at_uiuc/" target="_blank" rel="noopener noreferrer">Wade's GPA Site</a>.</p>
        <h3>Chart Page</h3>
          <p>Like the Course page, type in your department of interest to get data to display. Some bubbles may be small and hard to see if there are only a handful of reviews for a given course, but hovering over them still gives data on a course/courses.</p>
        <h3>Review Page</h3>
          <p>You do not need to choose a department for this page to work, but you may.</p>
        <h3>Review and Profile information</h3>
          <p>You must be logged in to Create Reviews and see/edit your profile.</p>
        </section>
      <h2>Suggestions, Bugs, and Contributing</h2>
        <p>This site is a work in progress. If you have suggestions or want to help out in a programming or non-programming role, please fill out <a href="https://forms.gle/DcxfzHEAYffuqziy5" target="_blank" rel="noopener noreferrer">this Google Form</a></p>
        <p>If you find a bug, please report it <a href="https://github.com/mrfixitalec/uiuc-course-hub/issues/new" target="_blank" rel="noopener noreferrer">here</a></p>
        <p>To contribute on <a href="https://github.com/mrfixitalec/uiuc-course-hub" target="_blank" rel="noopener noreferrer">Github</a>, please make a fork of the repo and open a PR. Feel free to tackle any outstanding features or issues too 😊</p>
  </main>
</body>